import React, { useState, useEffect } from "react";
import Marquee from "react-fast-marquee";
import { FaBullhorn } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import "./CustomNewsMarquee.css";
import {
  useGetNotificationsQuery,
  useUpdateNotificationStatusMutation,
} from "../../../services/userApi";

const CustomNewsMarquee = ({ isHide }) => {
  const [newsItems, setNewsItems] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const {
    data: getCustomNews,
    error,
    isLoading,
  } = useGetNotificationsQuery({ type: "custom news" });
  const [updateNotification] = useUpdateNotificationStatusMutation();

  // console.log("******* getCustomNews : ************", getCustomNews);

  useEffect(() => {
    if (getCustomNews?.data) {
      setNewsItems(getCustomNews?.data);
      setCurrentIndex(0);
    }
  }, [getCustomNews]);

  const handleRemoveNews = async (id) => {
    try {
      await updateNotification({ id }).unwrap();
      setNewsItems(newsItems.filter((news) => news._id !== id));
      setCurrentIndex((prevIndex) =>
        prevIndex < newsItems.length - 1 ? prevIndex + 1 : 0
      );
    } catch (err) {
      console.error("Error updating notification status:", err);
    }
  };

  if (!newsItems.length || isHide) return null;

  const currentNews = newsItems[currentIndex];

  return (
    <div className="marquee-container">
      <div className="marquee-icon-container">
        <FaBullhorn size={20} color="#FFFFFF" />
      </div>
      <div className="marquee-text-container">
        <Marquee
          className="marquee-text"
          speed={40}
          gradient={false}
          style={{ fontWeight: 600 }}
        >
          {currentNews?.notificationContent}
        </Marquee>
      </div>
      <button
        className="marquee-close-button"
        onClick={() => handleRemoveNews(currentNews._id)}
      >
        <IoClose />
      </button>
    </div>
  );
};

export default CustomNewsMarquee;
