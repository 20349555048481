import React, { useEffect, useRef, useState } from "react";
import AvatarDropdownMenu from "../avatarDropdownMenu";
import HeaderDropdown from "./HeaderDropdown/HeaderDropdown";
import {
  useClaimBtnQuery,
  useGetTotalPointsQuery,
  useGetWalletQuery,
  useMyProfileQuery,
  useGetNotificationsQuery,
} from "../../services/userApi";
import { IoGiftOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useClickOutside } from "./../../hooks/useClickOutside";
import { removeLocalStorage } from "./../../utils/function/localStorage";
import { IoMdNotifications } from "react-icons/io";
import "./Header.css";
import NotificationModal from "./NotificationModal/NotificationModal";
import { io } from "socket.io-client";
import { env } from "../../env";
import { jwtDecode } from "jwt-decode";
import { getLocalStorage } from "./../../utils/function/localStorage";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useSelector, useDispatch } from "react-redux";
import { setUserProfile } from "../../store/Slices/userProfileSlice";

const Header = ({ screenName, isGiftShow }) => {
  const {
    data: profileData,
    isLoading: profileIsLoading,
    error: profileErr,
    refetch: refetchGetMyProfile,
  } = useMyProfileQuery();

  // country = profileData?.data[0].country;
  // console.log("country---------------", country);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setUserProfile({ country: profileData?.data[0]?.country }));
  }, [profileData?.data]);

  let { country } = useSelector((state) => state.userProfile);

  const [visible, setVisible] = useState(false);
  const [showFirstButton, setShowFirstButton] = useState(true);
  const [notificationList, setNotificationList] = useState([]);
  const [isBellIconActive, setIsBellIconActive] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const bellIconRef = useRef(null);
  const [profileImageError, setProfileImageError] = useState(false);

  const { data: notifications, refetch: refetchNotifications } =
    useGetNotificationsQuery({ type: "bell icon" });

  const navigate = useNavigate();
  const { data: profile } = useMyProfileQuery();
  const { data: claimBtn } = useClaimBtnQuery();
  const { data: getTotalPoints, refetch: refetchTotalPoints } =
    useGetTotalPointsQuery();
  const { data: getWallet, refetch: refetchWallet } = useGetWalletQuery();
  // avater clckable menu
  const profileRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  useClickOutside(profileRef, () => setOpenMenu(false));
  // handle logout
  const handleLogout = () => {
    removeLocalStorage("tp_Aa_uth_access");
    window.location.reload();
  };

  const toggleNotificationModal = () => {
    const rect = bellIconRef.current.getBoundingClientRect();
    setModalPosition({ top: rect.bottom + 20, left: rect.left - 300 });

    setModalIsOpen(!modalIsOpen);
    setIsBellIconActive(!isBellIconActive); // Toggle the bell icon color
  };

  const closeNotificationModal = () => {
    setModalIsOpen(false);
    setIsBellIconActive(false); // Reset the bell icon color when modal closes
  };

  const token = getLocalStorage("tp_Aa_uth_access");
  let decodedUsername = null;
  if (token) {
    const decodedToken = jwtDecode(token);
    decodedUsername = decodedToken.username;
  }

  const socket = io(env.BASE_URL, {
    transports: ["websocket"],
  });

  useEffect(() => {
    setNotificationList(notifications?.data);
  }, [notifications]);

  useEffect(() => {
    socket.on("newNotification", (notification) => {
      console.log("Received new notification:", notification);
      if (notification.username === decodedUsername) {
        setNotificationList((prevNotifications) => [
          ...prevNotifications,
          notification,
        ]);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [socket, decodedUsername]);

  useEffect(() => {
    refetchNotifications();
    refetchWallet();
    refetchTotalPoints();
  }, [refetchNotifications, refetchWallet, refetchTotalPoints]);

  return (
    <div className="header-container">
      <div className="header-main-view">
        <div>
          <h2 className="header-title">
            {screenName?.length > 13
              ? `${screenName?.slice(0, 11)}...`
              : screenName}
          </h2>
        </div>
        <div className="header-actions">
          <button
            className="points-button"
            onClick={() => navigate("/home/all-point-history")}
          >
            <span className="points-text">
              {(getTotalPoints?.currentPoint || 0)
                .toFixed(3)
                .replace(/\.?0+$/, "")}
            </span>
            <img
              src="https://i.postimg.cc/1zKkRz5N/point.png"
              alt="points"
              className="points-icon"
            />
          </button>

          <button
            className="wallet-button"
            onClick={() => navigate("/home/wallet")}
          >
            {country === "India"
              ? `₹${getWallet?.data?.current_balance?.toFixed(2)}`
              : `$${(getWallet?.data?.current_balance / 90).toFixed(4)}`}
          </button>

          {isGiftShow && (
            <button
              className="gift-button"
              onClick={() => navigate("/home/referral")}
            >
              {showFirstButton ? (
                <div className="gift-button-content">
                  <IoGiftOutline size={17} />
                </div>
              ) : (
                <span className="gift-button-text">₹1000</span>
              )}
            </button>
          )}

          {/* Notification Icon */}
          {/* <div
            ref={bellIconRef}
            className="tpu__dashboard__header__icon"
            onClick={toggleNotificationModal}
            style={{
              color: isBellIconActive ? "black" : "#8c8787",
              cursor: "pointer",
              marginTop: "10px",
              marginRight: "10px",
            }} // Conditionally change color
          >
            <IoMdNotifications size={24} />
          </div> */}

          <div
            ref={bellIconRef}
            className="tpu__dashboard__header__icon"
            onClick={() => navigate("/home/notification")}
            style={{
              color: isBellIconActive ? "black" : "#8c8787",
              position: "relative",
              marginRight: "12px",
              cursor: "pointer",
            }}
          >
            {notificationList && notificationList.length > 0 && (
              <span className="notification-badge">
                {/* {notificationList.length} */}
                {notifications?.pagination?.total}
              </span>
            )}
            <IoMdNotifications size={24} />
          </div>
          <div style={{ position: "relative" }}>
            <div className="header-profile-progress">
              <CircularProgressbar
                value={(() => {
                  const progressItems = [
                    { id: "register", completed: true },
                    {
                      id: "email",
                      completed: profile?.data?.[0]?.isEmailVerified || false,
                    },
                    ...(!profile?.data?.[0]?.country ||
                    profile?.data?.[0]?.country === "India"
                      ? [
                          {
                            id: "mobile",
                            completed:
                              profile?.data?.[0]?.isMobileVerified || false,
                          },
                        ]
                      : []),
                    {
                      id: "profile_image",
                      completed:
                        profile?.data?.[0]?.profile_image_status || false,
                    },
                    {
                      id: "gender",
                      completed: Boolean(profile?.data?.[0]?.gender),
                    },
                    { id: "dob", completed: Boolean(profile?.data?.[0]?.dob) },
                    {
                      id: "address",
                      completed: Boolean(profile?.data?.[0]?.address),
                    },
                    {
                      id: "country",
                      completed:
                        profile?.data?.[0]?.hasOwnProperty("country") || false,
                    },
                    {
                      id: "whatsapp",
                      completed: Boolean(profile?.data?.[0]?.whatsapp),
                    },
                    {
                      id: "kyc",
                      completed: profile?.data?.[0]?.isKYCVerified || false,
                    },
                  ];
                  const completedCount = progressItems.filter(
                    (item) => item.completed
                  ).length;
                  return (completedCount / progressItems.length) * 100;
                })()}
                strokeWidth={5}
                styles={buildStyles({
                  pathColor: "#4CAF50",
                  trailColor: "#E0E0E0",
                  strokeLinecap: "round",
                })}
              />
              <button
                className="profile-button"
                onClick={() => setOpenMenu(!openMenu)}
              >
                <img
                  src={
                    profile?.data[0]?.profile_pic && !profileImageError
                      ? profile?.data[0]?.profile_pic
                      : profile?.data[0]?.gender === "Male"
                      ? "https://img.freepik.com/premium-vector/male-face-avatar-icon-set-flat-design-social-media-profiles_1281173-3806.jpg?ga=GA1.1.219331544.1738900157&semt=ais_hybrid"
                      : profile?.data[0]?.gender === "Female"
                      ? "https://img.freepik.com/premium-vector/person-with-blue-shirt-that-says-name-person_1029948-7040.jpg?ga=GA1.1.219331544.1738900157&semt=ais_hybrid"
                      : "https://cdn-icons-png.flaticon.com/512/1144/1144709.png"
                  }
                  alt="profile"
                  className="avatar"
                  onError={() => setProfileImageError(true)}
                />
              </button>
              <div className="header-progress-tooltip">
                {(() => {
                  const progressItems = [
                    { id: "register", completed: true },
                    {
                      id: "email",
                      completed: profile?.data?.[0]?.isEmailVerified || false,
                    },
                    ...(!profile?.data?.[0]?.country ||
                    profile?.data?.[0]?.country === "India"
                      ? [
                          {
                            id: "mobile",
                            completed:
                              profile?.data?.[0]?.isMobileVerified || false,
                          },
                        ]
                      : []),
                    {
                      id: "profile_image",
                      completed:
                        profile?.data?.[0]?.profile_image_status || false,
                    },
                    {
                      id: "gender",
                      completed: Boolean(profile?.data?.[0]?.gender),
                    },
                    { id: "dob", completed: Boolean(profile?.data?.[0]?.dob) },
                    {
                      id: "address",
                      completed: Boolean(profile?.data?.[0]?.address),
                    },
                    {
                      id: "country",
                      completed:
                        profile?.data?.[0]?.hasOwnProperty("country") || false,
                    },
                    {
                      id: "whatsapp",
                      completed: Boolean(profile?.data?.[0]?.whatsapp),
                    },
                    {
                      id: "kyc",
                      completed: profile?.data?.[0]?.isKYCVerified || false,
                    },
                  ];
                  const completedCount = progressItems.filter(
                    (item) => item.completed
                  ).length;
                  const percentage =
                    (completedCount / progressItems.length) * 100;
                  return `${
                    percentage % 1 === 0
                      ? Math.floor(percentage)
                      : percentage.toFixed(1)
                  }%`;
                })()}
              </div>
            </div>
            {openMenu && (
              <HeaderDropdown
                visible={openMenu}
                setVisible={setOpenMenu}
                navigation={navigate}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
