import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { env } from "../env";
import { getLocalStorage } from "../utils/function/localStorage";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: env.BASE_URL,
    // mode: "cors",
    prepareHeaders: (headers) => {
      headers.set("X-Auth-Token", getLocalStorage("tp_Aa_uth_access"));
      return headers;
    },
  }),
  tagTypes: ["User", "Task", "Profile", "leaderBoard", "Wallet"], // automatic-data fetching
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({
        url: "/login",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Auth"],
    }),
    getLoginUser: builder.query({
      query: () => {
        return {
          url: "/common/fetch_login_user",
          method: "GET",
        };
      },
      providesTags: ["User"], // automatic-data fetching
    }),
    getValidateEmail: builder.query({
      query: (email) => {
        return {
          url: `/validate_email?email=${email}`,
          method: "GET",
        };
      },
      providesTags: ["UserValidate"],
    }),
    addGoogleLogin: builder.mutation({
      // user register
      query: (body) => ({
        url: "/google_login",
        method: "POST",
        body: body,
      }),
    }),
    addLoginWithMobile: builder.mutation({
      query: (body) => ({
        url: "/login-with-mobile",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Auth"],
    }),
    findMobileNumberOnDatabase: builder.query({
      query: (mobile) => {
        return {
          url: `/find_mobile_no_in_database?mobile=${mobile}`,
          method: "GET",
        };
      },
      providesTags: ["Auth"],
    }),
    sendOtp: builder.mutation({
      query: (body) => {
        return {
          url: "/send-otp",
          method: "POST",
          body: body,
        };
      },
      providesTags: ["Auth"],
    }),
    checkOtpMatch: builder.mutation({
      query: (body) => ({
        url: "/check-otp-match",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Auth"],
    }),
    forgetPasswordWithMobile: builder.mutation({
      query: (body) => ({
        url: "/forget_password_with_mobile",
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["Auth"], // automatic-data fetching
    }),
    sendEmailOtp: builder.mutation({
      query: (body) => {
        return {
          url: "/send_email_otp",
          method: "POST",
          body: body,
        };
      },
      providesTags: ["Auth"],
    }),
    sendEmailForgotOtp: builder.mutation({
      query: (body) => ({
        url: "/forgot_password",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Auth"],
    }),
    checkEmailOtpMatch: builder.mutation({
      query: (body) => ({
        url: "/check-email-otp-match",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Auth"],
    }),
    addResetPass: builder.mutation({
      query: (body) => ({
        url: `/reset_password`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Auth"], // automatic-data fetching
    }),
    getManageSlider: builder.query({
      query: () => "/secure/get-manage-slider",
      providesTags: ["Task"],
    }),
    myProfile: builder.query({
      query: () => "/secure/profile",
      providesTags: ["Profile"],
    }),
    claimBtn: builder.query({
      query: () => "/secure/claim-button",
      providesTags: ["leaderBoard"],
    }),
    getTotalPoints: builder.query({
      query: () => "/secure/get_total_points",
      providesTags: ["leaderBoard"],
    }),
    getWallet: builder.query({
      query: () => "/secure/get-wallet",
      providesTags: ["Wallet"],
    }),
    addBank: builder.mutation({
      query: (body) => ({
        url: "/secure/create_payment_details",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Bank"],
    }),
    fetchBank: builder.query({
      query: () => ({
        url: "/secure/fetch_bank",
        method: "GET",
      }),
      providesTags: ["Bank"],
    }),
    deleteBank: builder.mutation({
      query: (bankId) => ({
        url: "/secure/delete_bank",
        method: "PUT",
        body: { id: bankId },
      }),
      invalidatesTags: ["Bank"],
    }),
    updateBank: builder.mutation({
      query: (body) => ({
        url: `/secure/update_payment_details`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["Bank"],
    }),
    deleteBankCard: builder.mutation({
      query: (body) => ({
        url: "/secure/delete_payment_details",
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Bank"],
    }),
    addUPI: builder.mutation({
      query: (body) => ({
        url: "/secure/addUpi",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Bank"],
    }),
    dailyLeaderBoard: builder.query({
      query: ({ page, limit }) =>
        `/secure/daily_leaderboard_live_data_business?page=${page}&limit=${limit}`,
      providesTags: ["leaderBoard"],
    }),
    weeklyLeaderBoard: builder.query({
      query: ({ page, limit }) =>
        `/secure/weekly_leaderboard_live_data_business?page=${page}&limit=${limit}`,
      providesTags: ["leaderBoard"],
    }),
    monthlyLeaderBoard: builder.query({
      query: ({ page, limit }) =>
        `/secure/monthly_leaderboard_live_data_business?page=${page}&limit=${limit}`,

      providesTags: ["leaderBoard"],
    }),
    getValidateSponsorId: builder.query({
      query: (sponsorid) => {
        return {
          url: `/sponsorid?username=${sponsorid}`,
          method: "GET",
        };
      },
    }),
    editImage: builder.mutation({
      query: (body) => ({
        url: "/secure/change_profile_pic",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Profile"],
    }),
    updateProfile: builder.mutation({
      query: (body) => ({
        url: "/secure/profile",
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["Profile"],
    }),
    allLeaderBoardHistory: builder.query({
      query: () => "/secure/all_leaderboard_history",
      providesTags: ["leaderBoard"],
    }),
    getUsername: builder.query({
      query: () => "/secure/get_username",
      providesTags: ["Auth"],
    }),
    myTotalReferrals: builder.query({
      query: ({ username }) => `/secure/total-referrals/${username}`,
      providesTags: ["Team"],
    }),
    selfEarning: builder.query({
      query: ({ page, limit }) =>
        `/secure/get-self-earning-history?page=${page}&limit=${limit}`,
      providesTags: ["Income"],
    }),
    referralEarning: builder.query({
      query: ({ page, limit }) =>
        `/secure/get-referral-earning-history?page=${page}&limit=${limit}`,
      providesTags: ["Income"],
    }),
    rewardIncome: builder.query({
      query: ({ page, limit }) =>
        `/secure/fetch_Reward_income?page=${page}&limit=${limit}`,
      providesTags: ["Income"],
    }),
    changeEmail: builder.mutation({
      query: (body) => ({
        url: "/secure/change_email",
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["Profile"],
    }),

    // OTP
    sendOtpSecure: builder.mutation({
      query: (body) => {
        return {
          url: "/secure/send-OPT",
          method: "POST",
          body: body,
        };
      },
      providesTags: ["Profile"],
    }),
    sendUpdateOtpSecure: builder.mutation({
      query: (body) => {
        return {
          url: "/secure/send-update-OTP",
          method: "POST",
          body: body,
        };
      },
      providesTags: ["Profile"],
    }),
    verifyMobile: builder.mutation({
      query: (body) => {
        return {
          url: "/secure/verify-mobile",
          method: "PUT",
          body: body,
        };
      },
      providesTags: ["Profile"],
    }),
    setDOB: builder.mutation({
      query: (body) => ({
        url: "/secure/set-dob",
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["Profile"],
    }),
    register: builder.mutation({
      query: (body) => ({
        url: "/register",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Auth"],
    }),
    getValidateUsername: builder.query({
      query: (username) => {
        return {
          url: `/validate_username?username=${username}`,
          method: "GET",
        };
      },
      providesTags: ["UserValidate"],
    }),
    getValidateMobile: builder.query({
      query: (mobile) => {
        return {
          url: `/validate_mobile?mobile=${mobile}`,
          method: "GET",
        };
      },
      providesTags: ["UserValidate"],
    }),
    addMobileRegister: builder.mutation({
      query: (body) => ({
        url: "/mobile_register",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Auth"],
    }),
    getLeaderBoardUserInfo: builder.query({
      query: (username) =>
        `/secure/get-leader-board-user-info?username=${username}`,
      providesTags: ["leaderBoard"],
    }),
    addKyc: builder.mutation({
      query: (body) => ({
        url: "/secure/create_KYC", // Adjust the URL for KYC submission
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Kyc"], // Invalidate KYC cache tags if necessary
    }),
    getKyc: builder.query({
      query: () => ({
        url: "/secure/get_KYC",
        method: "GET",
      }),
      providesTags: ["Kyc"],
    }),
    // getAvailableSocialMediaTasks: builder.query({
    //   query: ({ page, limit, task_profile, task_sub_profile }) =>
    //     `/secure/get-available-social-media-tasks?page=${page}&limit=${limit}&task_profile=${task_profile}&task_sub_profile=${task_sub_profile}`,
    //   providesTags: ["Available Social Media Tasks"],
    // }),
    // getAllMySMTHistory: builder.query({
    //   query: ({ page, limit, task_profile, task_sub_profile }) =>
    //     `/secure/get-all-my-social-media-task-history?page=${page}&limit=${limit}&task_profile=${task_profile}&task_sub_profile=${task_sub_profile}`,
    //   providesTags: ["Task"],
    // }),
    // getAllMySMTHistoryV2: builder.query({
    //   query: ({ page, limit, task_profile, task_sub_profile }) =>
    //     `/secure/get-all-my-social-media-task-history-v2?page=${page}&limit=${limit}&task_profile=${task_profile}&task_sub_profile=${task_sub_profile}`,
    //   providesTags: ["Task"],
    // }),
    getAllMySMTHistory: builder.query({
      query: ({ page, limit, task_profile, task_sub_profile }) => {
        const encodedTaskProfile = encodeURIComponent(task_profile || "");
        const encodedTaskSubProfile = encodeURIComponent(task_sub_profile || "");
    
        return `/secure/get-all-my-social-media-task-history?page=${page}&limit=${limit}&task_profile=${encodedTaskProfile}&task_sub_profile=${encodedTaskSubProfile}`;
      },
      providesTags: ["Task"],
    }),
    
    getAllMySMTHistoryV2: builder.query({
      query: ({ page, limit, task_profile, task_sub_profile }) => {
        const encodedTaskProfile = encodeURIComponent(task_profile || "");
        const encodedTaskSubProfile = encodeURIComponent(task_sub_profile || "");
    
        return `/secure/get-all-my-social-media-task-history-v2?page=${page}&limit=${limit}&task_profile=${encodedTaskProfile}&task_sub_profile=${encodedTaskSubProfile}`;
      },
      providesTags: ["Task"],
    }),
    
    // getSubmittedSMT: builder.query({
    //   query: ({ page, limit, task_profile, task_sub_profile }) =>
    //     `/secure/get-submitted-social-media-tasks?page=${page}&limit=${limit}&task_profile=${task_profile}&task_sub_profile=${task_sub_profile}`,
    //   providesTags: ["Task"],
    // }),
    getSubmittedSMT: builder.query({
      query: ({ page, limit, task_profile, task_sub_profile }) => {
        const encodedTaskProfile = encodeURIComponent(task_profile || "");
        const encodedTaskSubProfile = encodeURIComponent(task_sub_profile || "");
    
        return `/secure/get-submitted-social-media-tasks?page=${page}&limit=${limit}&task_profile=${encodedTaskProfile}&task_sub_profile=${encodedTaskSubProfile}`;
      },
      providesTags: ["Task"],
    }),
    
    submitSocialMediaTask: builder.mutation({
      query: (body) => ({
        url: "/secure/submit-social-media-task",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Task"],
    }),
    // getTotalAvailableSMT: builder.query({
    //   query: ({ page, limit, task_profile }) =>
    //     `/secure/get-available-social-media-tasks-point-count-v2?page=${page}&limit=${limit}&task_profile=${task_profile}`,
    //   providesTags: ["Total Available Social Media Tasks"],
    // }),
    getTotalAvailableSMT: builder.query({
      query: ({ page, limit, task_profile }) => {
        const encodedTaskProfile = encodeURIComponent(task_profile || "");
        
        return `/secure/get-available-social-media-tasks-point-count-v2?page=${page}&limit=${limit}&task_profile=${encodedTaskProfile}`;
      },
      providesTags: ["Total Available Social Media Tasks"],
    }),
    
    getPayment: builder.query({
      query: () => {
        return {
          url: "/secure/payUsingPhonePe",
          method: "GET",
        };
      },
      providesTags: ["Payment"],
    }),
    // getMyReferrals: builder.query({
    //   query: ({ page, limit, searchValue }) =>
    //     `/secure/referrals?page=${page}&limit=${limit}&searchValue=${searchValue}`,
    //   providesTags: ["Referrals"],
    // }),
    getMyReferrals: builder.query({
      query: ({ page, limit, searchValue, specificLevel }) =>
        `/secure/referral?page=${page}&limit=${limit}&searchValue=${searchValue}&specificLevel=${specificLevel}`,
      providesTags: ["Referrals"],
    }),
    getPromotionalLink: builder.query({
      query: () => "/secure/get_dynamic_promotional_link",
      providesTags: ["Promotional Link"],
    }),
    getLatestNews: builder.query({
      query: () => "/secure/get_latest_news_user",
      providesTags: ["userSupport"],
    }),
    getVerifiedKyc: builder.query({
      query: () => "/secure/get_Verified_KYC",
      method: "GET",
      providesTags: ["kyc"],
    }),
    changePassword: builder.mutation({
      query: (body) => ({
        url: "/secure/change_password",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Profile"],
    }),
    getTaskProfile: builder.query({
      query: (term) => `/common/get_profile_data?searchTerm=${term}`,
    }),
    getTaskSubProfile: builder.query({
      query: (query) =>
        `/common/get_sub_profile_data?searchTerm=${query?.term}&profile=${query?.profile}`,
    }),
    getAvailableSocialMediaTasks: builder.query({
      query: ({ page, limit, task_profile, task_sub_profile }) => {
        const encodedTaskSubProfile = encodeURIComponent(task_sub_profile || "");
        const encodedTaskProfile = encodeURIComponent(task_profile || "");
    
        return `/secure/get-available-social-media-tasks-v3?page=${page}&limit=${limit}&task_profile=${encodedTaskProfile}&task_sub_profile=${encodedTaskSubProfile}`;
      },
      providesTags: ["Available Social Media Tasks"],
    }),
    
    // getAvailableSocialMediaTasksPointCountV2: builder.query({
    //   query: ({ page, limit, task_profile, searchTerm }) =>
    //     `/secure/get-available-social-media-tasks-point-count-v2?page=${page}&limit=${limit}&task_profile=${task_profile}&searchTerm=${searchTerm}`,
    //   providesTags: ["Available Social Media Tasks"],
    // }),
    getAvailableSocialMediaTasksPointCountV2: builder.query({
      query: ({ page, limit, task_profile, searchTerm }) => {
        const encodedTaskProfile = encodeURIComponent(task_profile || "");
        const encodedSearchTerm = encodeURIComponent(searchTerm || "");
    
        return `/secure/get-available-social-media-tasks-point-count-v2?page=${page}&limit=${limit}&task_profile=${encodedTaskProfile}&searchTerm=${encodedSearchTerm}`;
      },
      providesTags: ["Available Social Media Tasks"],
    }),
    
    addLead: builder.mutation({
      query: (body) => ({
        url: "/secure/lead",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Team"],
    }),
    getMyCreatedLeads: builder.query({
      query: ({ page, limit }) =>
        `/secure/my-created-lead?page=${page}&limit=${limit}`,
      providesTags: ["Team"],
    }),
    getPopupImage: builder.query({
      query: () => "/common/get_popup_image",
      providesTags: ["Task"],
    }),
    UpdateNotificationStatus: builder.mutation({
      query: (body) => ({
        url: "/secure/updateNotificationStatus",
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: ["Notifications"],
    }),
    GetNotifications: builder.query({
      query: ({ type, page = 1, limit = 10 }) => {
        console.log(" ------ type -------", type); 
        return {
          url: `/secure/getNotification?type=${type}&page=${page}&limit=${limit}`,
          method: "GET",
        };
      },
      providesTags: ["Notifications"],
    }),    
    GetGlobalNotifications: builder.query({
      query: () => ({
        url: "/secure/getGlobalNotification",
        method: "GET",
      }),
      providesTags: ["Notifications"],
    }),
    getTaskStatusCount: builder.query({
      query: ({ page, limit, status }) =>
        `/secure/get_task_status_count?page=${page}&limit=${limit}${
          status && `&status=${status}`
        }`,
      providesTags: ["Task"],
    }),
    getWithdrawCharge: builder.query({
      query: () => `/secure/get-withdraw-charge`,
      providesTags: ["Withdraw"],
    }),
    getManageReferrals: builder.query({
      query: () => "/secure/ManageReferrals",
      method: "GET",
      providesTags: ["Profile"],
    }),
    getPopUp: builder.query({
      query: () => ({
        url: "/getPopUp/Business-Dashboard",
        method: "GET",
      }),
    }),
    getINRtoPointsConversionRatio: builder.query({
      query: () => "/secure/get_inr_to_point_conversion_ratio",
      providesTags: ["redeem"],
    }),
    submitRedeemAmount: builder.mutation({
      query: (data) => ({
        url: "/secure/submit_redeem_amount",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["redeem"],
    }),
    getSocialMediaPlatforms: builder.query({
      query: () => `/common/get-social-media-platforms`,
    }),
    getMySocialMediaHandles: builder.query({
      query: () => `/secure/get-my-social-media-handles`,
      providesTags: ["socialMediaHandle"],
    }),
    addSocialMediaHandle: builder.mutation({
      query: (body) => ({
        url: "/secure/add-social-media-handle",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Task"],
    }),

    updateLinkedTask: builder.mutation({
      query: (body) => ({
        url: "/secure/update-linked-task",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Task"],
    }),

    getSocialMediaLinkedTask: builder.query({
      query: (query) => `/secure/get-linked-task?task_id=${query.task_id}`,
    }),
    checkLinkedTaskCompletion: builder.query({
      query: (query) =>
        `/secure/checkLinkedTaskCompletion?task_id=${query.task_id}`,
    }),

    getAllSocialMediaTaskIds: builder.query({
      query: () => `/secure/get-all-task-Ids`,
    }),
    getSpecificSMT: builder.query({
      query: ({ task_id }) =>
        `/secure/get_specific_social_media_task?task_id=${task_id}`,
      providesTags: ["Task"],
    }),

    getAllLinkedTask: builder.query({
      query: (taskid) => `/secure/getLinkedTask?taskid=${taskid}`,
    }),

    getLastModalShown: builder.query({
      query: (taskProfile) => ({
        url: `/common/get-last-modal-shown?taskProfile=${taskProfile}`,
        method: "GET",
      }),
      providesTags: ["Profile"],
    }),

    updateLastModalShown: builder.mutation({
      query: (body) => ({
        url: "/common/update-modal-shown",
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["Profile"],
    }),
    hideEmailMobileWhatsApp: builder.mutation({
      query: ({ isMobileHidden, isEmailHidden, isWhatsAppHidden }) => {
        const params = new URLSearchParams();
        if (typeof isMobileHidden !== "undefined") {
          params.append("isMobileHidden", String(isMobileHidden));
        }
        if (typeof isEmailHidden !== "undefined") {
          params.append("isEmailHidden", String(isEmailHidden));
        }
        if (typeof isWhatsAppHidden !== "undefined") {
          params.append("isWhatsAppHidden", String(isWhatsAppHidden));
        }
        return {
          url: `/secure/hideEmailMobileWhatsApp?${params.toString()}`,
          method: "PUT",
        };
      },
      invalidatesTags: ["User"],
    }),
    getReferralCost: builder.query({
      query: () => "/secure/getReferralCost",
      providesTags: ["ReferralCost"],
    }),
    buyReferral: builder.mutation({
      query: (body) => ({
        url: "/secure/buyReferral",
        method: "POST",
        body,
      }),
      invalidatesTags: ["ReferralCost"],
    }),
    GetManageTabsStatus: builder.query({
      query: () => ({
        url: `/GetManageTabsStatus`,
        method: "GET",
      }),
    }),
    deleteAccount: builder.mutation({
      query: () => ({
        url: "/secure/delete_account",
        method: "PUT",
      }),
      invalidatesTags: ["delete"],
    }),

    // /getSpecificUserPremium_PlusAmount

    getSpecificUserPremium_PlusAmount: builder.query({
      query: () => "/secure/getSpecificUserPremium_PlusAmount",
      providesTags: ['getSpecificUserPremium_PlusAmount'],
    }),
    getBuyReferralHistory: builder.query({
      query: () => "/secure/getBuyReferralHistory",
      providesTags: ["ReferralHistory"],
    }),
    clearAllNotifications: builder.mutation({
      query: (body) => ({
        url: "/secure/clearAllNotifications",
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Notifications"],
    }),    
  }),
});

export const {
  useGetManageTabsStatusQuery,
  useCheckLinkedTaskCompletionQuery,
  useUpdateLinkedTaskMutation,
  useGetSocialMediaLinkedTaskQuery,
  useGetAllSocialMediaTaskIdsQuery,
  useLoginMutation,
  useGetValidateEmailQuery,
  useAddGoogleLoginMutation,
  useGetLoginUserQuery,
  useAddLoginWithMobileMutation,
  useFindMobileNumberOnDatabaseQuery,
  useSendOtpMutation,
  useCheckOtpMatchMutation,
  useForgetPasswordWithMobileMutation,
  useSendEmailOtpMutation,
  useSendEmailForgotOtpMutation,
  useCheckEmailOtpMatchMutation,
  useAddResetPassMutation,
  useGetManageSliderQuery,
  useMyProfileQuery,
  useClaimBtnQuery,
  useGetTotalPointsQuery,
  useGetWalletQuery,
  useAddBankMutation,
  useFetchBankQuery,
  useDeleteBankMutation,
  useUpdateBankMutation,
  useDeleteBankCardMutation,
  useAddUPIMutation,
  useGetValidateSponsorIdQuery,
  useEditImageMutation,
  useUpdateProfileMutation,
  useChangeEmailMutation,
  useSendOtpSecureMutation,
  useSendUpdateOtpSecureMutation,
  useVerifyMobileMutation,
  useSetDOBMutation,
  useRegisterMutation,
  useGetValidateUsernameQuery,
  useGetValidateMobileQuery,
  useGetUsernameQuery,
  useAddMobileRegisterMutation,
  useAllLeaderBoardHistoryQuery,
  useGetLeaderBoardUserInfoQuery,
  useDailyLeaderBoardQuery,
  useWeeklyLeaderBoardQuery,
  useMonthlyLeaderBoardQuery,
  useMyTotalReferralsQuery,
  useSelfEarningQuery,
  useReferralEarningQuery,
  useRewardIncomeQuery,
  useAddKycMutation,
  useGetKycQuery,
  useGetAllMySMTHistoryQuery,
  useGetAllMySMTHistoryV2Query,
  useGetTotalAvailableSMTQuery,
  useGetSubmittedSMTQuery,
  useSubmitSocialMediaTaskMutation,
  useLazyGetPaymentQuery,
  useGetMyReferralsQuery,
  useGetPromotionalLinkQuery,
  useGetLatestNewsQuery,
  useGetVerifiedKycQuery,
  useChangePasswordMutation,
  useGetTaskProfileQuery,
  useGetTaskSubProfileQuery,
  useGetAvailableSocialMediaTasksQuery,
  useGetAvailableSocialMediaTasksPointCountV2Query,
  useAddLeadMutation,
  useGetMyCreatedLeadsQuery,
  useGetPopupImageQuery,
  useUpdateNotificationStatusMutation,
  useGetNotificationsQuery,
  useGetGlobalNotificationsQuery,
  useGetTaskStatusCountQuery,
  useGetWithdrawChargeQuery,
  useGetManageReferralsQuery,
  useGetPopUpQuery,
  useGetINRtoPointsConversionRatioQuery,
  useSubmitRedeemAmountMutation,
  useGetSocialMediaPlatformsQuery,
  useGetMySocialMediaHandlesQuery,
  useAddSocialMediaHandleMutation,
  useGetSpecificSMTQuery,

  useGetAllLinkedTaskQuery,

  useGetLastModalShownQuery,
  useUpdateLastModalShownMutation,

  useHideEmailMobileWhatsAppMutation,
  useGetReferralCostQuery,
  useBuyReferralMutation,
  useDeleteAccountMutation,
  useGetSpecificUserPremium_PlusAmountQuery,
  useGetBuyReferralHistoryQuery,
  useClearAllNotificationsMutation,
} = userApi;
