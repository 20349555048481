import React, { useEffect } from "react";
import Helmet from "react-helmet";
import logo from "../assets/images/logo.png";
import { Toaster } from "react-hot-toast";
import Routers from "../routes";
import { useNavigate } from "react-router-dom";
import { getLocalStorage } from "../utils/function/localStorage";
import errorIcon from "../assets/images/error.png";
import successIcon from "../assets/images/checkmark.png";
const App = () => {
  const navigate = useNavigate();
  const token = getLocalStorage("tp_Aa_uth_access");
  // useEffect(() => {
    
  //     navigate("/home");
  
  // }, []);
  useEffect(() => {
    const token = getLocalStorage("tp_Aa_uth_access"); // Get login token
    const hasRefreshed = localStorage.getItem("app_refreshed");

    if (token && !hasRefreshed) {
      localStorage.setItem("app_refreshed", "true");
      window.location.reload();
    }
  }, []);
  return (
    <>
      <Helmet>
        <title>Taskplanet</title>
        <link rel="shortcut icon" href={logo} type="image/x-icon" />
        <link rel="apple-touch-icon" href={logo} />
      </Helmet>
      <Toaster
        position="top-center"
        reverseOrder={true}
        toastOptions={{
          error: {
            icon: (
              <img
                style={{ width: 24, height: 24 }}
                src={errorIcon}
                alt="error"
              />
            ),
            style: {
              background: "#F8100E",
              color: "#fff",
              fontWeight: "700 !important",
            },
          },

          success: {
            icon: (
              <img
                style={{ width: 24, height: 24 }}
                src={successIcon}
                alt="success"
              />
            ),
            style: {
              background: "#1ABF3D",
              color: "#fff",
              fontWeight: "700 !important",
            },
          },
        }}
      />
      <Routers />
    </>
  );
};

export default App;
