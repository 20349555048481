import React from "react";
import Marquee from "react-fast-marquee";
import { FaBullhorn } from "react-icons/fa";
import "./NewsMarquee.css";
import { useGetLatestNewsQuery } from "../../../services/userApi";

const NewsMarquee = ({ news, isHide }) => {
  const { data: getLatestNews } = useGetLatestNewsQuery();

  if (!getLatestNews?.data?.news || isHide) return null;

  const marqueeText = `${getLatestNews?.data?.news} ${" ".repeat(70)}`.repeat(
    30
  );

  return (
    <div className="marquee-container">
      <div className="marquee-icon-container">
        <FaBullhorn size={20} color="#FFFFFF" />
      </div>
      <div className="marquee-text-container">
        <Marquee className="marquee-text" speed={40} gradient={false}>
          {marqueeText}
        </Marquee>
      </div>
    </div>
  );
};

export default NewsMarquee;
