import React, { useEffect, useRef, useState } from "react";
import BottomSheet from "../../components/BottomSheet";
import { Link, useNavigate } from "react-router-dom";
import TopHeader from "../../components/TopHeader";
import { FaPhoneAlt } from "react-icons/fa";
import {
  useAddLoginWithMobileMutation,
  useFindMobileNumberOnDatabaseQuery,
  useSendOtpMutation,
} from "../../services/userApi";
import { Notification } from "../../components/ToastNotification";
import { savedLocalStorage } from "../../utils/function/localStorage";

const MobileLoginScreen = () => {
  const [isFocus, setFocus] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [countdown, setCountdown] = useState(40);
  const [otpCode, setOtpCode] = useState("");
  const navigate = useNavigate();

  // OTP Field ***********************************
  const inputRefs = useRef([]);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const handleChangeOtp = (value, index) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    if (value.length === 1 && index < 3) {
      inputRefs.current[index + 1].focus();
    }
    if (newOtp.every((item) => item !== "")) {
      setOtpCode(newOtp.join(""));
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (otp[index] === "" && index > 0) {
        inputRefs.current[index - 1].focus();
      } else {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
      }
    }
  };
  // OTP Field End *****************************

  const [data, setData] = useState({
    mobile: "+91",
  });

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  // Check Valid Mobile
  const { data: getValidateMobile, refetch } =
    useFindMobileNumberOnDatabaseQuery(data?.mobile);
  // Mobile Login
  const [
    addLoginWithMobile,
    {
      data: addLoginWithMobileData,
      error: addLoginWithMobileError,
      isLoading: addLoginWithMobileIsLoading,
    },
  ] = useAddLoginWithMobileMutation();
  useEffect(() => {
    if (addLoginWithMobileData?.message) {
      Notification(addLoginWithMobileData?.message, "success");
      localStorage.setItem(
        "tp_Aa_uth_access",
        addLoginWithMobileData?.data.token
      );
      const userCountry = addLoginWithMobileData?.data?.country || "India";
      localStorage.setItem("userCountry", userCountry);
      navigate("/home");
      window.location.reload();
    }
    if (addLoginWithMobileError) {
      if (addLoginWithMobileError?.data?.message === "OTP does not match!") {
        Notification(addLoginWithMobileError?.data?.message, "error");
      }
      if (addLoginWithMobileError?.data?.message !== "OTP does not match!") {
        savedLocalStorage(
          "loginUserInfo",
          JSON.stringify({ mobile: data?.mobile })
        );
        navigate("/mobileAdditionalInfo");
      }
    }
  }, [addLoginWithMobileError, addLoginWithMobileData]);

  // Send oTP
  const [
    sendOtp,
    { data: sendOtpData, error: sendOtpError, isLoading: sendOtpIsLoading },
  ] = useSendOtpMutation();

  useEffect(() => {
    if (sendOtpData) {
      setOtpSent(true);
      setCountdown(40);
      Notification(sendOtpData?.message, "success");
    }
    if (sendOtpError) {
      Notification(sendOtpError?.data?.message, "error");
    }
  }, [sendOtpData, sendOtpError]);

  const handleSubmitOtp = async (e) => {
    e.preventDefault();
    await sendOtp({ mobile: data?.mobile?.slice(3) });
  };
  // Login
  const handleSubmitLogin = async (e) => {
    e.preventDefault();
    const obj = {
      mobile: data?.mobile?.slice(3),
      otp: otpCode,
    };
    await addLoginWithMobile(obj);

    setOtp(["", "", "", ""]);
  };
  // OTP timer
  useEffect(() => {
    if (otpSent) {
      const timer = setInterval(() => {
        setCountdown((prevCount) => countdown > 0 && prevCount - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [otpSent, countdown]);

  return (
    <div className="tpu__mobileLoginScreen__wrapper layoutContainer">
      {/* Header */}
      <TopHeader title="Login/Register" para="Ready to play and earn?" />
      {/* Bottom Sheet */}
      <BottomSheet height="85%">
        <div className="tpu__mobileLoginScreen__bottomSheet__content">
          {!otpSent ? (
            <>
              <form onSubmit={handleSubmitOtp}>
                <div className="mobileLoginForm">
                  <div className="mobileLoginForm__heading">
                    <p>Enter your mobile number</p>
                    <p>We will send you OTP to verify</p>
                  </div>
                  <div className="mobileNumberField">
                    <span>
                      <FaPhoneAlt
                        style={{ color: isFocus ? "#0b59f9" : "#000" }}
                      />
                    </span>
                    <input
                      type="text"
                      name="mobile"
                      value={data.mobile}
                      className="mobileInput"
                      onChange={handleChange}
                      onFocus={() => setFocus(true)}
                      onBlur={() => setFocus(false)}
                    />
                  </div>
                  <Link to="/mobileForgotPassword" className="forgotPassword">
                    Forgot Password?
                  </Link>
                  {getValidateMobile && (
                    <p
                      style={{
                        color: getValidateMobile?.data ? "green" : "red",
                        fontSize: 12,
                      }}
                    >
                      {getValidateMobile?.data
                        ? data?.mobile?.slice(3).length === 10 &&
                          "Mobile number exist in database"
                        : data?.mobile?.slice(3).length === 10 &&
                          "Mobile number not exist in database"}
                    </p>
                  )}
                </div>

                <div className="submitButton">
                  <button
                    type="submit"
                    className="loginButton"
                    disabled={sendOtpIsLoading}
                  >
                    <span>{sendOtpIsLoading ? "Loading..." : "Send OTP"}</span>
                  </button>
                </div>
              </form>
            </>
          ) : (
            <form onSubmit={handleSubmitLogin}>
              <div className="mobileLoginForm">
                <div
                  className="mobileNumberField"
                  style={{
                    justifyContent: "center",
                    background: "transparent",
                    border: "none",
                  }}
                >
                  {otp.map((data, index) => (
                    <input
                      key={index}
                      ref={(el) => (inputRefs.current[index] = el)}
                      type="text"
                      maxLength="1"
                      value={data}
                      onChange={(e) => handleChangeOtp(e.target.value, index)}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      style={{
                        width: "40px",
                        height: "40px",
                        textAlign: "center",
                        margin: "5px",
                        fontSize: "22px",
                        outline: "none",
                        background: "#f4f7ff",
                        border: "1px solid lightgray",
                        borderRadius: "5px",
                      }}
                    />
                  ))}
                </div>
                <div
                  style={{
                    color: "gray",
                    margin: "10px 0px",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "4px",
                    fontFamily: "var(--font-family)",
                    fontSize: "14px",
                  }}
                >
                  Didn't receive OTP?
                  {countdown > 0 && (
                    <p>
                      {" "}
                      Resend in{" "}
                      <strong style={{ fontWeight: "700" }}>
                        {countdown} Seconds
                      </strong>
                    </p>
                  )}
                </div>
              </div>

              {countdown > 0 ? (
                <div className="submitButton">
                  <button
                    type="submit"
                    className="loginButton"
                    disabled={addLoginWithMobileIsLoading}
                  >
                    <span>
                      {addLoginWithMobileIsLoading ? "Loading..." : "Confirm"}
                    </span>
                  </button>
                </div>
              ) : (
                <div className="submitButton">
                  <button
                    type="button"
                    className="loginButton"
                    disabled={sendOtpIsLoading}
                    onClick={handleSubmitOtp}
                  >
                    <span>
                      {sendOtpIsLoading ? "Loading..." : "Resend OTP"}
                    </span>
                  </button>
                </div>
              )}
            </form>
          )}
        </div>
      </BottomSheet>
    </div>
  );
};

export default MobileLoginScreen;
