import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setTask } from "../../../store/Slices/smTaskSubTaskSlice";
import SocialMediaCreateAccountModal from "../Task/Modal/SocialMediaCreateAccountModal";
import {
  useGetLastModalShownQuery,
  useUpdateLastModalShownMutation,
  useGetTotalAvailableSMTQuery,
} from "../../../services/userApi";
import { useGetMySocialMediaHandlesQuery } from "../../../services/userApi";

const SocialMediaTaskButton = ({
  d,
  i,
  name,
  setIsTaskSelected,
  categoryCounts,
  taskplanetLogo,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLockedTaskOpen, setIsLockedTaskOpen] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState("");

  const [iconError, setIconError] = useState(new Set());

  // ✅ Define platforms that require account connection
  const lockedPlatforms = [
    "Instagram",
    "Facebook",
    "Twitter",
    "Telegram",
    "Youtube",
  ];

  // ✅ Fetch last modal shown from backend
  const { data: lastShownData, isLoading } = useGetLastModalShownQuery(name);
  const [updateLastModalShown] = useUpdateLastModalShownMutation();

  console.log("*-*-* lastShownData *-*-*: ", lastShownData);

  // ✅ Fetch task count
  const { data: getTotalAvailableSMT, refetch } = useGetTotalAvailableSMTQuery(
    {
      task_profile: name,
      limit: 999,
      term: "is_business_show",
    },
    {
      skip: !name,
    }
  );

  // ✅ Store task count in localStorage
  useEffect(() => {
    if (getTotalAvailableSMT?.documentCount) {
      const storedCounts = JSON.parse(
        localStorage.getItem("categoryCounts") || "{}"
      );
      storedCounts[name] = getTotalAvailableSMT.documentCount;
      localStorage.setItem("categoryCounts", JSON.stringify(storedCounts));

      categoryCounts(name, getTotalAvailableSMT.documentCount);
    }
  }, [getTotalAvailableSMT?.documentCount, name]);

  const totalPoint = getTotalAvailableSMT?.data?.docs?.reduce(
    (acc, cur) => acc + (cur?.point || 0),
    0
  );
  // console.log("TotalPoint", totalPoint)

  // ✅ Re-fetch data when `name` changes
  useEffect(() => {
    if (name) {
      const timer = setTimeout(() => {
        refetch();
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [name, refetch]);

  // ✅ Function to check if modal should be shown
  const shouldShowModal = () => {
    if (!lastShownData || !lastShownData.lastShownDate) {
      console.log("🛠 No lastShownDate found. Opening modal...");
      return true; // Open modal if no last shown date exists
    }

    const lastShownDate = new Date(lastShownData.lastShownDate);
    const today = new Date();

    // Convert both dates to YYYY-MM-DD format (UTC) to avoid timezone issues
    const lastShownUTC = lastShownDate.toISOString().split("T")[0];
    const todayUTC = today.toISOString().split("T")[0];

    console.log("🔍 Checking modal visibility:");
    console.log("lastShownDate (ISO):", lastShownData.lastShownDate);
    console.log("lastShownUTC:", lastShownUTC);
    console.log("todayUTC:", todayUTC);

    // Show modal if the stored date is different from today's date
    return lastShownUTC !== todayUTC;
  };

  const { data: socialMediaHandles } = useGetMySocialMediaHandlesQuery();

  // ✅ Handle Task Click
  const handleClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("Task button clicked for:", name);

    const cleanedName = name.trim();
    const isLockedTask = lockedPlatforms.includes(cleanedName);

    // ✅ Check if the selected platform already exists in the user's handles
    const existingHandle = socialMediaHandles?.data?.some(
      (handle) =>
        handle?.social_media?.toLowerCase() === cleanedName.toLowerCase()
    );

    if (existingHandle) {
      console.log(
        `🔒 User already has a handle for ${cleanedName}. Skipping modal.`
      );
      setIsTaskSelected(true);
      dispatch(setTask({ task: cleanedName }));
      return;
    }

    if (isLockedTask && shouldShowModal()) {
      setSelectedPlatform(cleanedName);
      setIsLockedTaskOpen(true);

      try {
        await updateLastModalShown({ taskProfile: cleanedName });
        console.log(
          `✅ Updated lastModalShown for ${cleanedName} in the database.`
        );
      } catch (error) {
        console.error(
          `❌ Error updating lastModalShown for ${cleanedName}:`,
          error
        );
      }

      return;
    }

    // const handleClick = (e) => {
    //   e.preventDefault();

    //   const button = e.currentTarget;
    //   button.classList.add("active");

    //   setTimeout(() => {
    //     button.classList.remove("active");
    //     navigate(/home/all-task?profile=${name}, {
    //       state: { profile: name },
    //     });
    //   }, 150);
    // };

    // ✅ If modal was shown today, directly open the sub-category list
    setIsTaskSelected(true);
    dispatch(setTask({ task: cleanedName }));
  };

  // ✅ Handle Connect Button Click
  const handleConnect = () => {
    console.log(
      `Redirecting to Add Social Media Handle for ${selectedPlatform}...`
    );
    setIsLockedTaskOpen(false);

    // ✅ Redirect to profile connection page
    setTimeout(() => {
      navigate(
        `/home/my-profile/add-social-media-handle?platform=${selectedPlatform}`
      );
    }, 300);
  };

  return (
    <>
      <div className="tpu__socialMedia__icon" onClick={handleClick}>
        <span className="amount__badge">
          {" "}
          +{getTotalAvailableSMT?.totalPoints || "0"}
        </span>
        <div className="tpu__media__icon">
          <img
            src={
              iconError.has(d.profile_icon?.url)
                ? taskplanetLogo
                : d.profile_icon?.url
            }
            alt="icon"
            onError={() =>
              setIconError((prev) => {
                const newSet = new Set(prev);
                newSet.add(d.profile_icon?.url);
                return newSet;
              })
            }
          />
        </div>
        <p className="media__name">{name}</p>
        <span className="tasks__badge">
          {" "}
          +{getTotalAvailableSMT?.documentCount || "0"} tasks
        </span>
      </div>

      {/* ✅ Locked Task Modal */}
      <SocialMediaCreateAccountModal
        isOpen={isLockedTaskOpen}
        handleClose={() => setIsLockedTaskOpen(false)}
        platform={selectedPlatform}
        onConnect={handleConnect}
      />
    </>
  );
};

export default SocialMediaTaskButton;
