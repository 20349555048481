/* eslint-disable */

import axios from "axios";
import Header from "../../components/Dashboard/Header";
import BottomMenu from "../../components/Dashboard/BottomMenu";
import NewsMarquee from "../../components/Dashboard/NewsMarquee/NewsMarquee";
import RedirectButton from "../../components/Dashboard/RedirectButtons/RedirectButtons";
import "./index.css";
import SocialMediaTaskButton from "./SocialMediaTask/SocialMediaTaskButton";
import { env } from "../../env";
import {
  IoCopyOutline,
  IoDiamondOutline,
  IoWallet,
  IoArrowForward,
  IoDiamondSharp,
  IoTimeOutline,
} from "react-icons/io5";
import { HiUserGroup } from "react-icons/hi";
import { BiDollar } from "react-icons/bi";
import { IoMdShare } from "react-icons/io";
import "react-awesome-slider/dist/styles.css";
import AwesomeSlider from "react-awesome-slider";
import {
  useGetLoginUserQuery,
  useGetManageSliderQuery,
  useGetWalletQuery,
  useMyTotalReferralsQuery,
  useGetTotalAmountOfAvailableSMTQuery,
  useGetPromotionalLinkQuery,
  // useGetPopupImageQuery,
  useMyProfileQuery,
  useGetPopUpQuery,
  useGetWithdrawChargeQuery,
  useGetTotalPointsQuery,
  useGetManageTabsStatusQuery,
  useGetSpecificUserPremium_PlusAmountQuery,
} from "../../services/userApi";
import {
  FaFacebook,
  FaInstagram,
  FaWhatsapp,
  FaYoutube,
  FaUserFriends,
} from "react-icons/fa";
import Advertisement from "./Advertisment/Advertisment";
import { PiTelegramLogoLight } from "react-icons/pi";
import { BsPeopleFill } from "react-icons/bs";
import { FaSquareXTwitter } from "react-icons/fa6";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { Notification } from "../../components/ToastNotification";
import { loginPopupDisplayHide } from "../../store/Slices/loginPopupDisplaySlice";
import { useSelector, useDispatch } from "react-redux";
import LoginPopUp from "../../components/Dashboard/LoginPopup/LoginPopup";
import CustomNewsMarquee from "../../components/Dashboard/CustomNewsMarquee/CustomNewsMarquee";
import Modal from "react-modal";
import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  LinkedinShareButton,
  InstapaperShareButton,
  InstapaperIcon,
  FacebookIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
  LinkedinIcon,
} from "react-share";
import { setUserProfile } from "../../store/Slices/userProfileSlice";
import PointIcon from "../../assets/images/Point.png";
import { Card, CardContent, Button, Typography, Box } from "@mui/material";
import BuyReferrals from "./Referral/buyReferrals";
import FlashNews from "./FlashNews/FlashNews";
import WhatsAppModal from "./Profile/Modal/WhatsAppModal/WhatsAppModal";
import { whatsAppModalPopupDisplayHide } from "../../store/Slices/whatsAppModalPopupDisplay";
const DashboardTopButton = ({ label, value, icon, color, onPress }) => {
  const handleClick = (e) => {
    e.preventDefault();

    const button = e.currentTarget;
    button.classList.add("active");

    setTimeout(() => {
      button.classList.remove("active");
      onPress();
    }, 150);
  };

  return (
    <div className="tpu__dashboard__top__button" onClick={handleClick}>
      <div className="tpu__dashboard__top__content">
        <p className="label">{label}</p>
        <div className="value__container">
          <p className="value" style={{ color }}>
            {value}
          </p>
          {icon === "wallet" && <IoWallet color={color} size={20} />}
          {icon === "point-logo" && (
            <img
              src={PointIcon}
              alt="Point"
              style={{ width: 20, height: 20 }}
            />
          )}
          {icon === "share-social" && <IoMdShare color={color} size={20} />}
        </div>
      </div>
    </div>
  );
};

const PremiumBox = ({ icon, text, onClick, showDiscountTag = false }) => {
  const { data, error, isLoading } =
  useGetSpecificUserPremium_PlusAmountQuery();

  if(data?.new_price_premium_plus || data?.new_price_premium ){
    showDiscountTag=true;
  }else{
    showDiscountTag=false;
  }
  const handleClick = (e) => {
    e.preventDefault();

    const box = e.currentTarget;
    box.classList.add("active");

    setTimeout(() => {
      box.classList.remove("active");
      onClick();
    }, 150);
  };

  return (
    <div className="premium__box" onClick={handleClick}>
      <span>{icon}</span>
      <p>{text}</p>

      {showDiscountTag && (
        <div className="discount-tag-container">
          <span className="discount-tag-text">Claim Discount Now</span>
          <div className="pulsing-dot" />
        </div>
      )}
    </div>
  );
};

const Home = () => {
  const API_URL = env.firebase_dynamic_link_api;
  const [showPopUp, setShowPopUp] = useState(false);
  const dispatch = useDispatch();
  const { isLoginPopupDisplay } = useSelector(
    (state) => state.loginPopupDisplay
  );
  const navigate = useNavigate();
  const [genLink, setGenLink] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: loginUser } = useGetLoginUserQuery();
  const { data: getManageSlider } = useGetManageSliderQuery();
  const [editWhatsAppModal, setEditWhatsAppModal] = useState(false);
  console.log("getManageSlider", getManageSlider);
  // const { openBuyReferral, referralQuantity } = useLocation().state;

  const { isWhatsAppModalPopupDisplay } = useSelector(
    (state) => state.whatsAppModalPopupDisplay
  );
  // const { data: getPopupImage, isSuccess: getPopupImageIsSuccess } =
  //   useGetPopupImageQuery();

  const { data: getPopUp, isSuccess: getPopUpIsSuccess } = useGetPopUpQuery();
  console.log(getPopUp);

  const { data: getTotalPoints, refetch: totalPointsRefetch } =
    useGetTotalPointsQuery();

  const [showPopupFirstTime, setShowPopupFirstTime] = useState(() => {
    // Check if we've shown the popup before
    return localStorage.getItem("hasShownPopup") !== "true";
  });
  const tabData = useGetManageTabsStatusQuery();
  console.log("tab object", tabData);
  //console.log("tab",tabData?.currentData?.referral_card_business_show);

  const {
    data: withdrawCharge,
    withdrawChargeLoading,
    withdrawChargeError,
    refetch,
  } = useGetWithdrawChargeQuery();

  //console.log("buy referral card", withdrawCharge?.data?.referral_card_business_show);

  const { data: getWallet } = useGetWalletQuery();
  const {
    data: myTotalReferrals,
    isLoading: myTotalReferralsIsLoading,
    refetch: myTotalReferralsRefetch,
    isFetching: myTotalReferralsIsFetching,
  } = useMyTotalReferralsQuery({ username: loginUser?.data?.username });

  console.log(myTotalReferrals)
  const {
    data: getPromotionalLink,
    error: getPromotionalLinkError,
    isLoading: getPromotionalLinkIsLoading,
    refetch: getPromotionalLinkRefetch,
  } = useGetPromotionalLinkQuery();

  const {
    data: getMyProfile,
    isLoading: getMyProfileIsLoading,
    error: profileErr,
    refetch: refetchGetMyProfile,
  } = useMyProfileQuery();

  console.log(getMyProfile);

  const homeSlider = getManageSlider?.data?.filter(
    (d) => d.location === "Home Header"
  );
  const homeCenterSlider = getManageSlider?.data?.filter(
    (d) => d.location === "Home Center"
  );

  // useEffect(() => {
  //   if (openBuyReferral && withdrawCharge?.data?.referral_card_business_show) {
  //     setIsModalOpen(true);
  //   }
  // }, [openBuyReferral]);

  const { country } = useSelector((state) => state.userProfile);

  const copyTextToCLipboard = () => {
    navigator.clipboard.writeText(genLink).then(() => {
      Notification("Link Copied to clipboard", "success");
    });
  };

  //handle create link
  const buildLink = async () => {
    const link = await axios({
      method: "POST",
      url: API_URL,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        dynamicLinkInfo: {
          domainUriPrefix: "https://jointaskplanet.page.link",
          link: `https://taskplanet.org/register?sponsorId=${loginUser?.data?.username}`, // Fallback link for non-Android devices
          androidInfo: {
            androidPackageName: "com.taskplanet",
            androidFallbackLink: `https://play.google.com/store/apps/details?id=com.taskplanet&referrer=sponsorId%3D${loginUser?.data?.username}`, // Link to Play Store if app is not installed
          },
          navigationInfo: {
            enableForcedRedirect: true, // Ensures the redirection is forced
          },
          socialMetaTagInfo: {
            socialTitle: "TaskPlanet",
            socialDescription: "Join TaskPlanet using the referral link!",
            socialImageLink:
              "https://res.cloudinary.com/djfzcmvmr/image/upload/v1715406169/TaskPlanet/banner_1_jja6q3.png", // Optional: Add a social media image
          },
        },
      },
    });
    if (link.status === 200) {
      return link.data.shortLink; // Return the shortened link
    } else {
      throw new Error(`Failed to create short link: ${link.statusText}`);
    }
  };

  useEffect(() => {
    if (getPopUpIsSuccess) {
      console.log("Fetched Popup Data:", getPopUp);
    }
    if (isLoginPopupDisplay === false && getPopUpIsSuccess) {
      setShowPopUp(true);
      dispatch(loginPopupDisplayHide());
    }
  }, [isLoginPopupDisplay, getPopUpIsSuccess, dispatch]);

  useEffect(() => {
    if (
      getMyProfile &&
      isWhatsAppModalPopupDisplay === false &&
      !getMyProfile?.data?.[0]?.whatsapp
    ) {
      setEditWhatsAppModal(true);
      dispatch(whatsAppModalPopupDisplayHide());
    }
  }, [isWhatsAppModalPopupDisplay, dispatch, getMyProfile]);

  useEffect(() => {
    if (getMyProfile?.data?.[0]?.country) {
      dispatch(
        setUserProfile({
          country: getMyProfile?.data?.[0]?.country || "India",
          // Add other fields if needed
        })
      );
    }
  }, [getMyProfile?.data]);

  const setUrl = async () => {
    try {
      // const shortLink = await buildLink('YOUR_FIREBASE_API_KEY'); // Replace with your Firebase API key
      const shortLink = await buildLink();
      setGenLink(shortLink);
    } catch (error) {
      console.error("Error creating short link:", error);
      setGenLink(
        `https://taskplanet.org/register?sponsorid=${loginUser?.data?.username}`
      );
    }
  };

  useEffect(() => {
    setUrl();
    // buildLink();
  }, [loginUser?.data?.username]);

  //telegram share
  const handleTeShareButtonClick = () => {
    const postText = "Join Taskplanet";
    const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(
      genLink
    )}&text=${encodeURIComponent(postText)}`;
    window.open(telegramUrl, "_blank");
  };

  //facebook share
  const handleFbShareButtonClick = () => {
    const appUrl = genLink;
    const postUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      appUrl
    )}`;
    window.open(postUrl, "_blank");
  };

  //whatsapp share
  const shareOnWhatsApp = () => {
    const url = genLink;
    const message = encodeURIComponent("Join Taskplanet: " + url);

    const whatsappUrl = `https://wa.me/?text=${message}`;
    window.open(whatsappUrl, "_blank");
  };

  const handlePromotionalClick = (e, href) => {
    e.preventDefault();

    const button = e.currentTarget;
    button.classList.add("active");

    setTimeout(() => {
      button.classList.remove("active");
      window.open(href, "_blank");
    }, 150);
  };

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const customModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      width: "300px",
      borderRadius: "10px",
      padding: "20px",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 999999,
    },
  };

  const shareModalStyles = `
    .share-modal-title {
      text-align: center;
      margin-bottom: 18px !important;
      font-size: 18px;
      font-weight: 500;
    }

    .share-buttons-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 15px;
      justify-items: center;
    }

    .share-button-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
    }

    .share-button-wrapper p {
      margin-top: 5px;
      font-size: 12px;
    }
  `;

  const ShareModal = ({ isOpen, onClose, shareUrl }) => {
    console.log("shareUrl", shareUrl);

    const message = `Hey there! Ready to earn money effortlessly? 🚀

Come join me on Task Planet – the ultimate money-earning app where you complete simple tasks and win rewards! Plus, get a fantastic 900 Points Welcome Bonus 🥳. Plus, you have a chance to earn 30,000+ points per referral 🎉

Here's how to start:
1️⃣ Download the Task Planet App: ${shareUrl}
2️⃣ Use my Sponsor ID: ${getMyProfile?.data[0]?.username}
3️⃣ Complete tasks & start earning instantly! 💰

 So what are you waiting for? Let's earn big together! 💸🔥`;
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        style={customModalStyles}
        contentLabel="Share Modal"
      >
        <style>{shareModalStyles}</style>
        <h3 className="share-modal-title">Share via</h3>
        <div className="share-buttons-grid">
          <div className="share-button-wrapper">
            <WhatsappShareButton url={message}>
              <WhatsappIcon size={40} round />
            </WhatsappShareButton>
            <p>WhatsApp</p>
          </div>

          <div className="share-button-wrapper">
            <FacebookShareButton url={message}>
              <FacebookIcon size={40} round />
            </FacebookShareButton>
            <p>Facebook</p>
          </div>

          <div className="share-button-wrapper">
            <TelegramShareButton url={message}>
              <TelegramIcon size={40} round />
            </TelegramShareButton>
            <p>Telegram</p>
          </div>

          <div className="share-button-wrapper">
            <div
              onClick={() => {
                navigator.clipboard.writeText(message);
                window.open("https://www.instagram.com", "_blank");
                Notification(
                  "Link copied! You can now paste it on Instagram",
                  "success"
                );
                onClose();
              }}
              style={{
                width: 40,
                height: 40,
                borderRadius: "50%",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <img
                src="https://cdn-icons-png.flaticon.com/512/2111/2111463.png"
                alt="Instagram"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
            <p>Instagram</p>
          </div>

          <div className="share-button-wrapper">
            <LinkedinShareButton url={message}>
              <LinkedinIcon size={40} round />
            </LinkedinShareButton>
            <p>Linkedin</p>
          </div>

          <div className="share-button-wrapper">
            <TwitterShareButton url={message}>
              <TwitterIcon size={40} round />
            </TwitterShareButton>
            <p>Twitter</p>
          </div>

          <div className="share-button-wrapper">
            <EmailShareButton
              url={message}
              subject="Join TaskPlanet!"
              body="Check this out:"
            >
              <EmailIcon size={40} round />
            </EmailShareButton>
            <p>Email</p>
          </div>

          <div
            className="share-button-wrapper"
            onClick={() => {
              navigator.clipboard.writeText(message);
              Notification("Link copied to clipboard!", "success");
            }}
          >
            <div
              style={{
                width: 40,
                height: 40,
                borderRadius: "50%",
                backgroundColor: "#6e6e6e",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IoCopyOutline color="#FFFFFF" size={20} />
            </div>
            <p>Copy Link</p>
          </div>
        </div>
      </Modal>
    );
  };

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    // This will make sure we show at least a loading state for 1-2 seconds
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // Handle loading failures
    const dataLoadingCheck = setTimeout(() => {
      if (!getMyProfile || !getWallet || !getManageSlider) {
        console.warn("Data loading incomplete after timeout");
        setHasError(true);
      }
    }, 5000); // 5 second timeout

    return () => clearTimeout(dataLoadingCheck);
  }, [getMyProfile, getWallet, getManageSlider]);

  useEffect(() => {
    try {
      // Log critical data to help debug
      console.log("Login User Data:", !!loginUser);
      console.log("Wallet Data:", !!getWallet);
      console.log("Profile Data:", !!getMyProfile);
      console.log("Slider Data:", !!getManageSlider);

      // If we have all required data but something essential is missing
      if (loginUser && getWallet && getMyProfile && !getMyProfile.data?.[0]) {
        console.error("Critical data structure missing", {
          loginUserExists: !!loginUser,
          walletExists: !!getWallet,
          profileExists: !!getMyProfile,
          profileDataExists: !!getMyProfile?.data,
          profileFirstItemExists: !!getMyProfile?.data?.[0],
        });
        setHasError(true);
      }
    } catch (err) {
      console.error("Error in data validation:", err);
      setHasError(true);
    }
  }, [loginUser, getWallet, getMyProfile, getManageSlider]);

  return (
    <div className="tpu__dashboard__wrapper layoutContainer">
      <div
        className="layoutContainer"
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        {/* Header */}
        <Header screenName="Home" isGiftShow />
        {editWhatsAppModal && showPopUp === false && (
          <WhatsAppModal
            modal={editWhatsAppModal}
            setModal={setEditWhatsAppModal}
            data={getMyProfile?.data?.[0]}
            title={"Update WhatsApp"}
          />
        )}
        {getPopUpIsSuccess && getPopUp?.data?.imageUrl && (
          <LoginPopUp
            modal={showPopUp}
            setModal={setShowPopUp}
            imageUrl={getPopUp.data.imageUrl} // Pass imageUrl from the response
            link={getPopUp.data.link} // Pass link from the response
            showPopupFirstTime={showPopupFirstTime}
            setShowPopupFirstTime={setShowPopupFirstTime}
          />
        )}
        {/* Content Body */}
        <div
          className="tpu__content__area"
          style={{ flex: 1, overflowY: "auto" }}
        >
          {/* News Marquee */}
          <NewsMarquee />

          {/* Custom News Marquee */}
          <CustomNewsMarquee />

          {/* Username Display */}
          <div style={{ paddingLeft: "10px", paddingBottom: "10px" }}>
            <span
              style={{
                fontSize: "14px",
                fontWeight: "500",
                backgroundColor: "#45bbff",
                padding: "0 6px",
                borderRadius: "5px",
                color: "#fff",
                display: "inline-block",
              }}
            >
              {getMyProfile?.data[0]?.username || "No username found"}
            </span>
          </div>
          <FlashNews />

          {/* Dashboard Top Buttons section  */}
          <div className="tpu__dashboard__top__buttons">
            <DashboardTopButton
              label="Wallet"
              value={`${country === "India" ? "₹" : "$"}${
                country === "India"
                  ? getWallet?.data?.current_balance
                      ?.toFixed(2)
                      .replace(/\.?0+$/, "")
                  : (getWallet?.data?.current_balance / 90)
                      .toFixed(4)
                      .replace(/\.?0+$/, "")
              }`}
              icon="wallet"
              color="green"
              onPress={() => navigate("/home/wallet")}
            />
            <DashboardTopButton
              label="Points"
              value={
                getTotalPoints?.currentPoint
                  ?.toFixed(3)
                  .replace(/\.?0+$/, "") || 0
              }
              icon="point-logo"
              color="#fccd0f"
              onPress={() => navigate("/home/earning")}
            />
            <DashboardTopButton
              label="Referrals"
              value={myTotalReferrals?.totalDocs || 0}
              icon="share-social"
              color="#2463fe"
              onPress={() => navigate("/home/referral")}
            />
          </div>

          <Advertisement
            getSliderAdvertisement={getManageSlider?.data?.filter(
              (data) =>
                data?.location === "Home Header" && data?.type === "web_app"
            )}
          />

          {/* New Promotional Card */}
          {(!getPromotionalLink?.type ||
            getPromotionalLink?.type === "home") && (
            <div className="tpu__promotional__card">
              <div className="tpu__promotional__content">
                <div className="tpu__promotional__text">
                  <h2>{getPromotionalLink?.title}</h2>
                  {/* <p>{getPromotionalLink?.description}</p> */}
                  <img
                    src={
                      getPromotionalLink?.icon?.url ||
                      "https://cdn-icons-png.flaticon.com/512/17524/17524829.png"
                    }
                    alt="promotional icon"
                  />
                </div>
                <div className="tpu__promotional__description">
                  <p>{getPromotionalLink?.description}</p>
                </div>
              </div>
              <a
                href={getPromotionalLink?.link}
                onClick={(e) =>
                  handlePromotionalClick(e, getPromotionalLink?.link)
                }
                className="tpu__promotional__button"
              >
                <span>{getPromotionalLink?.button_text}</span>
                <IoArrowForward />
              </a>
            </div>
          )}

          {/* Share Link Card */}
          <div
            href={genLink}
            onClick={() => setIsShareModalOpen(true)}
            className="tpu__shareLink__card "
          >
            <IoMdShare size={20} style={{ marginRight: "10px" }} />
            <span>Share Your Refer Link</span>
          </div>

          {/* Premium Section */}
          <div className="tpu__premium__section">
            <div className="tpu__premium__section__boxes">
              <PremiumBox
                icon={<IoDiamondSharp />}
                text="Premium Membership"
                onClick={() => navigate("/home/premium")}
                showDiscountTag={true}
              />
              <PremiumBox
                icon={<HiUserGroup />}
                text="My Leads"
                onClick={() => navigate("/home/my-leads")}
              />
            </div>
          </div>

          {/* buy referral card */}
          {tabData?.currentData?.referral_card_business_show && (
            <>
              <div
                onClick={() => navigate("/home/buy-referral")}
                className="tpu__referral__card"
              >
                <div className="tpu__referral__content">
                  <div className="tpu__referral__header">
                    <div className="tpu__referral__iconContainer">
                      <FaUserFriends size={22} color="#FFFFFF" />
                    </div>
                    <span className="tpu__referral__label">
                      REFERRAL PROGRAM
                    </span>
                  </div>

                  <h2 className="tpu__referral__title">
                    Boost Your Earnings by Buying Referrals!
                  </h2>

                  <p className="tpu__referral__descriptionText">
                    Get high-quality referrals and maximize your rewards.
                  </p>
                </div>

                <div className="tpu__referral__buttonContainer">
                  <button
                    className="tpu__referral__primaryButton"
                    // onClick={() => setIsModalOpen(true)}
                    onClick={() => navigate("/home/buy-referral")}
                  >
                    <span>Buy Referral Now</span>
                    <IoArrowForward size={16} color="#FFFFFF" />
                  </button>
                  <button
                    className="tpu__referral__secondaryButton"
                    onClick={() => navigate("/home/referral-purchase-history")}
                  >
                    <IoTimeOutline size={16} color="#4A6FFF" />
                    <span>View History</span>
                  </button>
                </div>
              </div>
            </>
          )}

          {isModalOpen && (
            <BuyReferrals
              isVisible={setIsModalOpen}
              onClose={() => setIsModalOpen(false)}
              // referralQuantity={referralQuantity}
            />
          )}
          {/* stake card */}
          {tabData?.currentData?.stake_card_business_show && (
            <div
              onClick={() =>
                navigate("/home/stake-points", {
                  state: {
                    getMyProfile,
                  },
                })
              }
              style={{ cursor: "pointer" }}
              className="stake__card"
            >
              <div className="stake__card__content">
                <div className="stake__card__text">
                  <h2>Wanna grow your Taskplanet Points?</h2>

                  <img src={PointIcon} alt="point icon" />
                </div>
                <div className="stake__card__description">
                  <p>Get interest up to 96℅ on your stake TaskPlanet Points</p>
                </div>
              </div>
              {console.log(getMyProfile)}
              <a style={{ cursor: "pointer" }} className="stake__card__button">
                <span>Stake Your Points</span>
                <span className="new-tag">NEW</span>
              </a>
            </div>
          )}

          {/*trading card*/}

          {/* <div onClick={()=>{
            navigate("/home/trading")
          }}  style={{cursor:"pointer"}} className="trade__card">
            <div className="trade__card__content">
              <div className="trade__card__text">
                <h2>Earn more points by participating in trades </h2>

                {/* <img src={PointIcon} alt="point icon" /> */}
          {/* </div>
              <div className="trade__card__description">
                <p>Trade, Win, and Level Up!</p>
              </div>
            </div>
            {console.log(getMyProfile)}
            <a style={{cursor:"pointer"}}
             
              className="trade__card__button"
            >
              <span >Trade Now </span>
              <span className="new-tag">NEW</span>
            </a>
          </div> */}

          {/* Home Center Slider */}
          <Advertisement
            getSliderAdvertisement={getManageSlider?.data?.filter(
              (data) =>
                data?.location === "Home Center" && data?.type === "web_app"
            )}
          />
          {/* Connect With Us */}
          <div className="tpu__connectWithUs__card">
            <div className="tpu__connectWithUs__header">
              <h2>Connect With Us</h2>
            </div>
            <div className="tpu__connectWithUs__buttons">
              <a
                href="https://whatsapp.com/channel/0029Va9B7dJGOj9lzu9XG13Z"
                target="_blank"
                rel="noopener noreferrer"
                className="tpu__connectWithUs__button whatsapp"
              >
                <img
                  src="https://cdn-icons-png.flaticon.com/512/3938/3938041.png"
                  alt="WhatsApp"
                  className="social-icon"
                />
              </a>
              <a
                href="https://t.me/taskplanetpro"
                target="_blank"
                rel="noopener noreferrer"
                className="tpu__connectWithUs__button telegram"
              >
                <img
                  src="https://cdn-icons-png.flaticon.com/512/3536/3536661.png"
                  alt="Telegram"
                  className="social-icon"
                />
              </a>
              <a
                href="https://www.youtube.com/@taskplanet3285"
                target="_blank"
                rel="noopener noreferrer"
                className="tpu__connectWithUs__button youtube"
              >
                <img
                  src="https://cdn-icons-png.flaticon.com/512/3938/3938026.png"
                  alt="YouTube"
                  className="social-icon"
                />
              </a>
              <a
                href="https://x.com/taskplanetpro"
                target="_blank"
                rel="noopener noreferrer"
                className="tpu__connectWithUs__button twitter"
              >
                <img
                  src="https://cdn-icons-png.flaticon.com/512/5968/5968830.png"
                  alt="Twitter"
                  className="social-icon"
                />
              </a>
              <a
                href="https://www.instagram.com/taskplanetpro"
                target="_blank"
                rel="noopener noreferrer"
                className="tpu__connectWithUs__button instagram"
              >
                <img
                  src="https://cdn-icons-png.flaticon.com/512/2111/2111463.png"
                  alt="Instagram"
                  className="social-icon"
                />
              </a>
              <a
                href="https://www.facebook.com/taskplanet.org"
                target="_blank"
                rel="noopener noreferrer"
                className="tpu__connectWithUs__button facebook"
              >
                <img
                  src="https://cdn-icons-png.flaticon.com/512/733/733547.png"
                  alt="Facebook"
                  className="social-icon"
                />
              </a>
            </div>
          </div>
        </div>
        {/* Bottom Menu */}
        <BottomMenu />
      </div>
      <ShareModal
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
        shareUrl={genLink}
      />
    </div>
  );
};

export default Home;
