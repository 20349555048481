import React, { useEffect, useRef, useState } from "react";
import SliderDots from "./SliderDots/SliderDots";
import "./Advertisment.css";
import { useNavigate } from "react-router-dom";
const Advertisement = ({ getSliderAdvertisement }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const slidesRef = useRef(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (getSliderAdvertisement?.length) {
        if (currentIndex < getSliderAdvertisement.length - 1) {
          setCurrentIndex((prevIndex) => prevIndex + 1);
          const slider = slidesRef.current;
          if (slider) {
            slider.scrollTo({
              left: (currentIndex + 1) * slider.offsetWidth,
              behavior: "smooth",
            });
          }
        } else {
          setCurrentIndex(0);
          const slider = slidesRef.current;
          if (slider) {
            slider.scrollTo({
              left: 0,
              behavior: "smooth",
            });
          }
        }
      }
    }, 8000);

    return () => clearInterval(intervalId);
  }, [currentIndex, getSliderAdvertisement]);

  if (!getSliderAdvertisement?.length) {
    return null;
  }

  return (
    <div className="advertisement-container">
      <div className="slider-container">
        <div
          className="slider-wrapper"
          ref={slidesRef}
          onScroll={(e) => {
            const scrollX = e.target.scrollLeft;
            const slideWidth = e.target.offsetWidth;
            setCurrentIndex(Math.round(scrollX / slideWidth));
          }}
        >
          {getSliderAdvertisement.map((item) => (
            <Slider key={item._id} item={item} />
          ))}
        </div>
        <SliderDots
          slides={getSliderAdvertisement}
          currentIndex={currentIndex}
        />
      </div>
    </div>
  );
};

export default Advertisement;

export const Slider = ({ item }) => {
  const navigate = useNavigate(); 

  const handleClick = () => {
    if (item?.category === "url") {
      window.open(item?.link, "_blank");
    } else if (item?.category === "screen") {
      navigate(item?.link); // Use navigate instead of navigation.navigate()
    } else if (item?.category === "webview") {
      navigate("/slider-web-view", {
        state: { link: item?.link, location: item?.location },
      });
    }
  };

  return (
    <div className="slider-image-container">
      <div onClick={handleClick} className="slider-image-wrapper">
        <img
          src={item?.sliderLink?.url}
          alt="slider"
          className="slider-image"
        />
      </div>
    </div>
  );
};
